<template>
  <Layout>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">
            Home
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Profile
        </li>
      </ol>
    </nav>
    <div class="my-3 my-md-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto">
            <h4>My Profile</h4>
            <div :class="['dimmer', saving ? 'active' : '']">
              <div class="loader"></div>
              <div class="dimmer-content">
                <div class="card">
                  <div class="card-body">
                    <ValidationObserver v-slot="{ passes }">
                      <form
                        class="form needs-validation"
                        novalidate
                        @submit.prevent="passes(updateProfile)"
                      >
                        <div class="row">
                          <div class="col-auto mb-2">
                            <span
                              class="avatar avatar-xl"
                              :style="{
                                backgroundImage: `url(${userProfileImage})`,
                              }"
                            ></span>
                            <a
                              href="#"
                              class="d-block text-center"
                              @click.prevent="$refs.profileImage.click()"
                              >Change</a
                            >
                            <input
                              ref="profileImage"
                              style="display: none"
                              type="file"
                              accept="image/jpg,image/png,image/gif"
                              @change="onProfileImageChange"
                            />
                          </div>
                          <div class="col">
                            <ValidationProvider
                              v-slot="{ errors, classes, invalid }"
                              name="username"
                              rules="required|min:3"
                            >
                              <div class="form-group">
                                <label class="form-label">Username</label>
                                <input
                                  v-model="profile.username"
                                  class="form-control"
                                  :class="{ 'is-invalid': invalid }"
                                  placeholder="KegBit User"
                                />
                                <span class="invalid-feedback">{{
                                  errors[0]
                                }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="form-label">Email</label>
                          <input
                            v-model="userProfile.email"
                            class="form-control"
                            placeholder="your-email@domain.com"
                            :disabled="!canUpdateEmail"
                          />
                          <p
                            v-show="!canUpdateEmail"
                            class="alert alert-info mt-2"
                            role="alert"
                          >
                            Unable to change your email. Your email is linked to
                            your sign in method.
                          </p>
                        </div>
                        <!-- <div class="form-group">
                      <label class="custom-switch pl-0">
                        <input
                          type="checkbox"
                          name="custom-switch-checkbox"
                          class="custom-switch-input"
                        />
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">
                          Send me email updates
                        </span>
                      </label>
                    </div> -->
                        <div class="form-group">
                          <label class="form-label">Location</label>
                          <input
                            v-model="profile.location"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group">
                          <label class="form-label">Time Zone</label>
                          <select v-model="profile.tzCode" class="form-control">
                            <option
                              v-for="(tz, idx) in timezones"
                              :key="idx"
                              :value="tz.tzCode"
                              >{{ tz.label }}</option
                            >
                          </select>
                        </div>
                        <div class="form-footer">
                          <p>
                            All of the fields on this page are optional and can
                            be deleted at any time, and by filling them out,
                            you're giving us consent to share this data wherever
                            your user profile appears. Please see our privacy
                            statement to learn more about how we use this
                            information.
                          </p>
                          <button class="btn btn-primary"
                            >Update Profile</button
                          >
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/Main';
import Jimp from 'jimp';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { auth } from '@src/services/firebase/init';
import { minimalTimezoneSet } from '@src/utils/compact-timezones';
import { getArrayBuffer } from '@utils/imageHelpers.js';

export default {
  name: 'UserProfile',
  components: { Layout, ValidationObserver, ValidationProvider },
  props: { userProfile: Object },
  data: function() {
    return {
      timezones: minimalTimezoneSet,
      userProfileImage: this.userProfile.avatar,
      firebaseUser: auth.currentUser,
      profile: {
        username: this.userProfile.username,
        location: this.userProfile.location,
        tzCode: this.userProfile.tzCode,
      },
      saving: false,
    };
  },
  computed: {
    canUpdateEmail() {
      const providerId = this.firebaseUser.providerData[0].providerId;
      return !['google.com', 'github.com'].includes(providerId);
    },
  },
  mounted() {
    if (!this.profile.tzCode) {
      this.profile.tzCode = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  },
  methods: {
    async onProfileImageChange(event) {
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format

        const ab = await getArrayBuffer(input.files[0]);

        const img = await Jimp.read(ab);
        // console.log('img', img);

        const buff = await img.scaleToFit(250, 250).getBufferAsync('image/png');
        // console.log('buff', buff);

        const url = await this.$store.dispatch('user/setUserAvatar', {
          avatar: buff,
        });

        this.userProfileImage = url;
      }
      // const base64 = await getBase64(file);
      // this.$refs.canvas.createImageByUrl(base64);
    },
    async updateProfile() {
      this.saving = true;
      await this.$store.dispatch('user/updateProfile', this.profile);
      this.saving = false;
    },
  },
};
</script>

<style>
.chart-stats {
  min-height: 300px;
}
</style>
